import { useState, useEffect } from 'react';
import * as React from 'react';
import { useCollapse } from 'react-collapsed';

import { ExpandableBody } from './components/expandable-body';
import { ExpandableHead } from './components/expandable-head';
import type { BorderRadius } from '../box';
import { Box } from '../box';

interface Props {
  children: React.ReactElement[];
  removeStyling?: boolean;
  expanded?: boolean;
  onToggleRow?: (isExpanded: boolean) => void;
  shouldToggleOnClick?: boolean;
  disabled?: boolean;
  borderRadius?: BorderRadius;
}

export function ExpandableRow({
  children,
  removeStyling = false,
  expanded = false,
  disabled = false,
  onToggleRow,
  shouldToggleOnClick = true,
  borderRadius = 3,
}: Props): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    duration: 200,
  });

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const headProps = {
    getToggleProps: getToggleProps({
      onClick: () => {
        if (!shouldToggleOnClick) {
          onToggleRow?.(isExpanded);
          return;
        }

        setIsExpanded((oldExpanded) => !oldExpanded);
        onToggleRow?.(!isExpanded);
      },
      disabled,
    }),
    isExpanded,
  };

  const bodyProps = {
    getCollapseProps,
  };

  if (removeStyling) {
    return (
      <>
        {React.cloneElement(children[0], headProps)}
        {React.cloneElement(children[1], bodyProps)}
      </>
    );
  }

  return (
    <Box border={1} borderRadius={borderRadius} boxShadow="card" style={{ overflow: 'hidden', background: '#fff' }}>
      {React.cloneElement(children[0], headProps)}
      {React.cloneElement(children[1], bodyProps)}
    </Box>
  );
}

export { ExpandableHead, ExpandableBody };
