import type { SavedAddress } from '@newfront-insurance/address-api';
import {
  IconCross,
  IconPlus,
  IconSearch,
  Input,
  useOnClickOutside,
  CircleSpinnerInline,
  useDebouncedCallback,
} from '@newfront-insurance/core-ui';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverClose,
  cn,
  buttonVariants,
} from '@newfront-insurance/core-ui/v2';
import Fuse from 'fuse.js';
import noop from 'lodash/noop';
import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import type { usAutocompletePro } from 'smartystreets-javascript-sdk';

import type { Suggestion, Address } from '../../../types';
import { getFormattedAddress } from '../../../utils/format-street-address';
import { useCreateAddress } from '../../hooks/use-create-address';
import { useKeyboardControls } from '../../hooks/use-keyboard';
import { useSavedAddresses } from '../../hooks/use-saved-addresses';
import { useSmartyStreets } from '../../hooks/use-smartystreets';
import { CreateAddressModal } from '../create-address-modal';
import { LookupAddress } from '../lookup-address';
import { SuggestedAddress } from '../suggested-address';

interface Props {
  accountUuid: string;
  disabled?: boolean;
  onChange: (address: SavedAddress | undefined) => unknown;
  selectedAddress?: SavedAddress;
  width?: number | string;
  disableCloseCustomAddressModalOnEscape?: boolean;
}

export function AddressInput({
  accountUuid,
  disabled = false,
  onChange,
  selectedAddress,
  width = 400,
  disableCloseCustomAddressModalOnEscape,
}: Props): JSX.Element {
  const [lookupAddress, lookupLoading] = useSmartyStreets();
  const [addressQuery, setAddressQuery] = useState<string | undefined>(undefined);
  const [lookupResults, setLookupResults] = useState<usAutocompletePro.Lookup | undefined>(undefined);
  const [isCustomAddressModalOpen, setIsCustomAddressModalOpen] = useState<boolean>(false);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const popoverContainerRef = useRef<HTMLDivElement>(null);

  const { mutateAsync: createAddress } = useCreateAddress();

  const { onKeyDown } = useKeyboardControls({
    inputRef,
    popoverRef: popoverContainerRef,
    onEsc: () => handleReset(),
  });

  /**
   * immediately fetch previously saved addresses
   */
  const { data: previouslySavedAddresses } = useSavedAddresses({ accountUuid });
  const previouslySavedAddressesResult = previouslySavedAddresses?.savedAddressResults;

  /** reset the component */
  function handleReset(): void {
    setLookupResults(undefined);
    setIsPopoverOpen(false);
    setAddressQuery(undefined);
  }

  /**
   * On click outside of the inputContainerRef address field
   * - reset the address query to undefined
   * - check if the element clicked was the address-lookup, if so, return and NOT close popover
   * - otherwise close popover and reset setAddressQuery
   */
  useOnClickOutside(inputContainerRef, (event: MouseEvent | TouchEvent): void => {
    if (!event.target) return;
    const { name } = event.target as HTMLButtonElement;

    // if clicking on search field, return
    if (name === 'address-lookup') return;

    // close the popover
    setIsPopoverOpen(false);
  });

  /**
   * on select of a smarty streets suggestion
   * - trigger onChange
   * - save address
   * - close popover
   * - reset setAddressQuery
   * - reset existing results
   */
  const handleSuggestionSelect = async (addressSuggestion: Address): Promise<void> => {
    setIsPopoverOpen(false);
    setAddressQuery(undefined);

    const response = await createAddress({
      accountUuid,
      address: addressSuggestion,
    });

    onChange(response.createdAddress);

    if (lookupResults) setLookupResults(undefined);
  };

  /**
   * on select of a saved address result
   * - trigger onChange
   * - close popover
   * - reset setAddressQuery
   * - reset existing results
   */
  const handleSavedAddressSelect = async (resultSelectedAddress: SavedAddress): Promise<void> => {
    setIsPopoverOpen(false);
    setAddressQuery(undefined);

    onChange(resultSelectedAddress);

    if (lookupResults) setLookupResults(undefined);
  };

  /**
   * Fuzzy search for previously saved addresses.
   * Initialize Fuse and Determine what fields to search against.
   * Memoize filteredSavedAddresses and return filtered results
   */
  const fuseFilterSavedAddresses = new Fuse(previouslySavedAddressesResult || [], {
    keys: ['addressLine1', 'addressLine2', 'city', 'stateOrArea', 'postalCode'],
    fieldNormWeight: 1,
  });

  const filteredSavedAddresses = useMemo(() => {
    if (addressQuery) {
      const filteredResults = fuseFilterSavedAddresses.search(addressQuery);
      return filteredResults.map((res) => res.item);
    }
  }, [addressQuery, fuseFilterSavedAddresses]);

  /**
   * If the popover is open, set focus to the address text field
   */
  useEffect(() => {
    if (isPopoverOpen) inputRef.current?.focus();
  }, [isPopoverOpen]);

  const DEBOUNCE_SEARCH = 500;

  /**
   * On input, debounce lookup
   * if empty (e.g. fieldValue === "") - reset the component
   */
  const searchFetch = useCallback(
    (value: string) => {
      async function fetchData(lookupAddressQuery: string): Promise<void> {
        const results = await lookupAddress(lookupAddressQuery, 5);
        setLookupResults(results);
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      if (value) fetchData(value);
    },
    [lookupAddress, setLookupResults],
  );

  const debouncedSearchFetch = useDebouncedCallback(searchFetch, DEBOUNCE_SEARCH);

  /**
   * renderPreviouslySavedAddresses
   * render previously saves addresses, or FILTERED previously saves addresses
   */
  function renderPreviouslySavedAddresses(savedAddresses: SavedAddress[], query?: string): JSX.Element | undefined {
    if (savedAddresses.length === 0) return;
    return (
      <div>
        <div className="px-4 py-2">
          <div className="text-sm font-semibold text-steel-300">Suggestions</div>
        </div>
        <div className="previously-saved-addresses">
          {savedAddresses?.slice(0, 5).map((savedAddress) => (
            <PopoverClose className="block w-full" key={`${savedAddress.addressLine1}-${savedAddress.addressLine2}`}>
              <SuggestedAddress
                onKeyDown={onKeyDown}
                address={savedAddress}
                handleSelectedResult={(addr: SavedAddress) => handleSavedAddressSelect(addr)}
                addressQuery={query}
              />
            </PopoverClose>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={`relative w-[${width}]`}>
        <Popover>
          <div ref={inputContainerRef}>
            {/* Display selected address, or prompt */}
            {disabled ? (
              <InitialAddressInput disabled={disabled} selectedAddress={selectedAddress} />
            ) : (
              <PopoverTrigger className="w-full text-left">
                <InitialAddressInput
                  isPopoverOpen={isPopoverOpen}
                  selectedAddress={selectedAddress}
                  handleToggle={setIsPopoverOpen}
                />
              </PopoverTrigger>
            )}
            {selectedAddress && (
              <div
                className="absolute right-[16px] top-[50%] -translate-y-[50%] "
                tabIndex={0}
                role="button"
                onKeyDown={noop}
                onClick={() => {
                  onChange(undefined);
                  handleReset();
                }}
              >
                <IconCross size={12} />
              </div>
            )}
          </div>
          <PopoverContent ref={popoverContainerRef} className="z-[5000] w-[416px] p-2" side="bottom" sideOffset={-150}>
            <div ref={popoverContainerRef} className={`w-[${width}] relative`}>
              <div className="max-h-[500px] overflow-y-scroll">
                <div className="p-4">
                  {/* smartystreets lookup input */}
                  <Input
                    data-address-lookup
                    autoComplete="off"
                    className="address-lookup"
                    innerRef={inputRef}
                    name="address-lookup"
                    type="search"
                    placeholder="Search"
                    onChange={(inputValue) => {
                      setAddressQuery(inputValue);

                      if (debouncedSearchFetch) {
                        debouncedSearchFetch(inputValue);
                      }
                    }}
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                    value={addressQuery}
                  />
                </div>
                {/* if addressQuery DOES NOT exist, and we have previouslySavedAddressesResult, render them */}
                {!addressQuery &&
                  previouslySavedAddressesResult &&
                  renderPreviouslySavedAddresses(previouslySavedAddressesResult?.slice(0, 5))}
                {/* if addressQuery DOES exist, and we have filteredSavedAddresses, filter/render them */}
                {addressQuery &&
                  filteredSavedAddresses &&
                  renderPreviouslySavedAddresses(filteredSavedAddresses, addressQuery)}
                {/* loading state */}
                {addressQuery && (
                  <div className="px-4 py-2">
                    <div className="flex">
                      <div className="text-sm font-semibold text-steel-300">
                        Search for <div className="inline font-bold text-steel-500">{addressQuery}</div>
                      </div>
                      {lookupLoading && <CircleSpinnerInline />}
                    </div>
                  </div>
                )}
                {/* SmartyStreets lookup results */}
                {addressQuery && addressQuery !== '' && lookupResults && lookupResults?.result.length > 0 && (
                  <div className=" lookup-results relative">
                    {lookupLoading && (
                      <div className="absolute bottom-0 left-0 right-0 top-0 z-[5] bg-white opacity-90" />
                    )}
                    {lookupResults.result.map((suggestion: Suggestion) => (
                      <PopoverClose className="block w-full" key={`${suggestion.streetLine}-${suggestion.secondary}`}>
                        <LookupAddress
                          suggestion={suggestion}
                          handleSelectedResult={handleSuggestionSelect}
                          addressQuery={addressQuery}
                          onKeyDown={onKeyDown}
                        />
                      </PopoverClose>
                    ))}
                  </div>
                )}
                {/* Trigger CreateAddressModal */}
                <div className="p-3">
                  <PopoverClose
                    className="block w-full"
                    id="open-add-address-modal"
                    onClick={() => setIsCustomAddressModalOpen(true)}
                  >
                    <div
                      className={cn(
                        'relative block flex w-full items-center justify-center after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:content-[""]',
                        buttonVariants({ variant: 'secondary' }),
                      )}
                    >
                      <div className="flex items-center gap-2">
                        <IconPlus color="#262D46" />
                        Add custom address
                      </div>
                    </div>
                  </PopoverClose>
                </div>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
      <CreateAddressModal
        accountUuid={accountUuid}
        isOpen={isCustomAddressModalOpen}
        toggle={setIsCustomAddressModalOpen}
        onSubmit={onChange}
        disableCloseOnEscape={disableCloseCustomAddressModalOnEscape}
      />
    </>
  );
}

function InitialAddressInput({
  disabled,
  handleToggle,
  isPopoverOpen,
  selectedAddress,
}: {
  disabled?: boolean;
  handleToggle?: (val: boolean) => unknown;
  isPopoverOpen?: boolean;
  selectedAddress?: Address;
}): JSX.Element {
  return (
    <div
      tabIndex={0}
      role="button"
      onKeyDown={noop}
      onClick={() => (handleToggle ? handleToggle(!isPopoverOpen) : noop)}
      className={cn(
        'block h-[40px] w-full  appearance-none rounded-sm border border-solid border-steel-200 bg-white py-2 pl-2  pr-[28px] text-base leading-[21px] text-steel-500',
        {
          'cursor-not-allowed bg-steel-50 ': disabled,
        },
      )}
    >
      {selectedAddress && (
        <div className="text inline-block w-full overflow-x-hidden truncate whitespace-nowrap">
          {getFormattedAddress(selectedAddress)}
        </div>
      )}
      {!selectedAddress && (
        <div>
          <div className="text-steel-300">No address selected</div>
          {!isPopoverOpen && (
            <div className="absolute right-[16px] top-[50%] -translate-y-[50%] ">
              <IconSearch size={12} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
