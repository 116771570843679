import { ProgramType } from '@newfront-insurance/account-api';
import { hasAnyAccountViewAccessScopes } from '@newfront-insurance/admin-ui/layout';
import { useFeatureFlag } from '@newfront-insurance/app-providers/src/providers/feature-flags';
import { AM_ROLES, AuthRole, PRODUCER_ROLES } from '@newfront-insurance/auth-api';
import { useHasAllScopes, useHasAnyScope } from '@newfront-insurance/next-auth';

import { useIsOwnedByCurrentUser } from './use-is-owned-by-current-user';
import type { DetailedAccount } from '../features/account-details/queries/use-detailed-account';

import type { WithEmployeeRoleMappings } from '@/api/types';
import { AuthProvider } from '@/client/providers/auth';

interface UsePermissionOptions {
  hasAll?: boolean;
  scopes: AuthRole[];
}

export function usePermission(opts: UsePermissionOptions): boolean {
  const { scopes, hasAll } = opts;
  const hasAnyScope = useHasAnyScope(AuthProvider, scopes);
  const hasAllScopes = useHasAllScopes(AuthProvider, scopes);

  if (hasAll) {
    return hasAllScopes;
  }

  return hasAnyScope;
}

export function useCanViewFullAccount(account: WithEmployeeRoleMappings | undefined): boolean {
  const isOwnedByCurrentUser = useIsOwnedByCurrentUser(account);

  const hasAnyAccess = usePermission({
    scopes: hasAnyAccountViewAccessScopes,
  });

  return isOwnedByCurrentUser || hasAnyAccess;
}

export function useCanCreateBilling(): boolean {
  return usePermission({
    scopes: [AuthRole.ACCOUNT_MANAGER, AuthRole.CSA, AuthRole.ENGINEER, AuthRole.OPERATIONS],
  });
}

export function useCanBulkImportServicingTeams(): boolean {
  return usePermission({
    scopes: [AuthRole.SERVICE_OPS, AuthRole.AM_MANAGER, AuthRole.PRODUCER_MANAGER, AuthRole.ENGINEER],
  });
}

export function useCanManageFollowupDates(): boolean {
  return usePermission({
    scopes: [AuthRole.PRODUCER, AuthRole.ENGINEER],
  });
}

export function useCanManageContacts(account: WithEmployeeRoleMappings): boolean {
  const hasAdminAccess = usePermission({
    scopes: [
      AuthRole.SERVICE_OPS,
      AuthRole.AM_MANAGER,
      AuthRole.CLIENT_SERVICE_TEAM_ADMIN,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.SALES_MANAGER,
      AuthRole.CONVERSION,
      AuthRole.ENGINEER,
    ],
  });
  const hasOwnershipAccess = useHasOwnershipAccess(account, [
    AuthRole.ACCOUNT_MANAGER,
    AuthRole.CSA,
    AuthRole.PRODUCER,
  ]);

  return (hasAdminAccess || hasOwnershipAccess) && !!account.isNewfrontBrokerage;
}

export function useCanLoadAccountSummariesDashboard(): boolean {
  return usePermission({
    scopes: [AuthRole.ACCOUNT_MANAGER, AuthRole.CSA, AuthRole.ENGINEER, AuthRole.PRODUCER],
  });
}

export function useCanDownloadProofOfInsurance(): boolean {
  return usePermission({
    scopes: [AuthRole.ACCOUNT_MANAGER, AuthRole.CSA, AuthRole.ENGINEER, AuthRole.OPERATIONS],
  });
}

export function useCanCreateCommercialAccount(): boolean {
  return usePermission({
    scopes: [AuthRole.ENGINEER, AuthRole.SERVICE_OPS],
  });
}

export function useCanCreatePersonalAccount(): boolean {
  return usePermission({
    scopes: [
      AuthRole.ENGINEER,
      AuthRole.PL_ACCOUNT_MANAGER,
      AuthRole.PL_PRODUCER,
      AuthRole.AM_MANAGER,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.GROWTH_CALLER,
      AuthRole.CONVERSION,
      AuthRole.SERVICE_OPS,
    ],
  });
}

export function useCanSelectProducer(): boolean {
  return usePermission({
    scopes: [AuthRole.ENGINEER, AuthRole.PRODUCER_MANAGER, AuthRole.SALES_MANAGER, AuthRole.RISK_MANAGER],
  });
}

/**
 * @deprecated the use of this hook is discouraged. Prefer defining your feature-specific hook for
 * the roles that you need. This hook is going to be removed soon because the definition of what an
 * admin is is going to change.
 */
export function useIsLegacyAdmin(): boolean {
  return usePermission({
    scopes: [AuthRole.ACCOUNT_MANAGER, AuthRole.CSA, AuthRole.ENGINEER, AuthRole.OPERATIONS, AuthRole.SALES_MANAGER],
  });
}

export function useCanManageLinesOfCoverage(): boolean {
  const canManageLinesOfCoverage = usePermission({
    scopes: [AuthRole.ACCOUNT_MANAGER, AuthRole.CSA, AuthRole.OPERATIONS, AuthRole.ENGINEER],
  });
  return canManageLinesOfCoverage;
}

export function useIsAccountManager(): boolean {
  return usePermission({ scopes: [AuthRole.ACCOUNT_MANAGER] });
}

export function useIsAccountManagerManager(): boolean {
  return usePermission({ scopes: [AuthRole.AM_MANAGER] });
}

export function useIsClAccountManager(): boolean {
  return usePermission({ scopes: [AuthRole.CL_ACCOUNT_MANAGER] });
}

export function useIsPlAccountManager(): boolean {
  return usePermission({ scopes: [AuthRole.PL_ACCOUNT_MANAGER] });
}

export function useIsClientServiceTeamAdmin(): boolean {
  return usePermission({ scopes: [AuthRole.CLIENT_SERVICE_TEAM_ADMIN] });
}

export function useCanManageAnyAccountManagementTeamMember(): boolean {
  return usePermission({
    scopes: [
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.CLIENT_SERVICE_TEAM_ADMIN,
      AuthRole.CONVERSION,
      AuthRole.CSA,
      AuthRole.ENGINEER,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.SALES_MANAGER,
      AuthRole.SERVICE_OPS,
    ],
  });
}

export function useIsProducer(): boolean {
  return usePermission({ scopes: [AuthRole.PRODUCER] });
}

export function useCanManageAnyProducerTeamMember(): boolean {
  return usePermission({
    scopes: [
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.CSA,
      AuthRole.ENGINEER,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.SALES_MANAGER,
      AuthRole.SERVICE_OPS,
    ],
  });
}

export function useCanManageAnyClientServiceAssociate(): boolean {
  return usePermission({
    scopes: [
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.CLIENT_SERVICE_TEAM_ADMIN,
      AuthRole.CONVERSION,
      AuthRole.CSA,
      AuthRole.ENGINEER,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.SALES_MANAGER,
      AuthRole.SERVICE_OPS,
    ],
  });
}

export function useIsConversionTeamMember(): boolean {
  return usePermission({ scopes: [AuthRole.CONVERSION] });
}

export function useIsCsa(): boolean {
  return usePermission({ scopes: [AuthRole.CSA] });
}

export function useIsEngineer(): boolean {
  return usePermission({ scopes: [AuthRole.ENGINEER] });
}

export function useIsGrowthCaller(): boolean {
  return usePermission({ scopes: [AuthRole.GROWTH_CALLER] });
}

export function useCanManageClaims(): boolean {
  return usePermission({
    scopes: [
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.CSA,
      AuthRole.ENGINEER,
      AuthRole.RISK_MANAGER,
      AuthRole.INSURANCE_OPS,
      AuthRole.OPERATIONS,
    ],
  });
}

export function useCanGrantClientAccess(account: WithEmployeeRoleMappings | undefined): boolean {
  const hasAdminAccess = usePermission({
    scopes: [AuthRole.SERVICE_OPS, AuthRole.AM_MANAGER, AuthRole.PRODUCER_MANAGER, AuthRole.ENGINEER],
  });
  const hasOwnershipAccess = useHasOwnershipAccess(account, [
    AuthRole.ACCOUNT_MANAGER,
    AuthRole.CSA,
    AuthRole.PRODUCER,
    AuthRole.EB_ACCOUNT_MANAGER,
    AuthRole.EB_PRODUCER,
  ]);
  return hasAdminAccess || hasOwnershipAccess;
}

export function useCanSeeInvoices(): boolean {
  return usePermission({
    scopes: [AuthRole.ACCOUNT_MANAGER, AuthRole.CSA, AuthRole.OPERATIONS, AuthRole.ENGINEER],
  });
}

function useHasOwnershipAccess(account: WithEmployeeRoleMappings | undefined, scopes: AuthRole[]): boolean {
  const isOwnedByCurrentUser = useIsOwnedByCurrentUser(account);
  const hasOwnershipScopes = usePermission({ scopes });

  return isOwnedByCurrentUser && hasOwnershipScopes;
}

export function useCanManageAffiliates(account: WithEmployeeRoleMappings): boolean {
  const hasAdminAccess = usePermission({
    scopes: [
      AuthRole.AM_MANAGER,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.SALES_MANAGER,
      AuthRole.SERVICE_OPS,
      AuthRole.ENGINEER,
    ],
  });
  const hasOwnershipAccess = useHasOwnershipAccess(account, [
    AuthRole.ACCOUNT_MANAGER,
    AuthRole.CSA,
    AuthRole.PRODUCER,
  ]);

  return hasAdminAccess || hasOwnershipAccess;
}

export function useCanAddAffinityGroups(account: WithEmployeeRoleMappings): boolean {
  const hasAdminAccess = usePermission({
    scopes: [AuthRole.PRODUCER_MANAGER, AuthRole.SALES_MANAGER],
  });
  const hasOwnershipAccess = useHasOwnershipAccess(account, [AuthRole.PRODUCER]);

  return hasAdminAccess || hasOwnershipAccess;
}

export function useCanRemoveAffinityGroups(): boolean {
  return usePermission({ scopes: [AuthRole.PRODUCER_MANAGER, AuthRole.SALES_MANAGER, AuthRole.ENGINEER] });
}

export function useCanUnarchiveAccount(account: WithEmployeeRoleMappings): boolean {
  const hasAdminAccess = usePermission({
    scopes: [AuthRole.AM_MANAGER, AuthRole.PRODUCER_MANAGER, AuthRole.SALES_MANAGER, AuthRole.ENGINEER],
  });
  const hasOwnershipAccess = useHasOwnershipAccess(account, [
    AuthRole.ACCOUNT_MANAGER,
    AuthRole.CSA,
    AuthRole.PRODUCER,
  ]);

  return hasAdminAccess || hasOwnershipAccess;
}

export function useCanDebugAnyUser(): boolean {
  return usePermission({ scopes: [AuthRole.ENGINEER] });
}

export function useCanManagePolicyTermFields(): boolean {
  return usePermission({
    scopes: [AuthRole.ACCOUNT_MANAGER, AuthRole.CSA],
  });
}

export function useCanManagePolicyStage(): boolean {
  return usePermission({
    scopes: [
      AuthRole.AM_MANAGER,
      AuthRole.BUSINESS_OPERATIONS,
      AuthRole.SERVICE_OPS,
      AuthRole.FINANCE,
      AuthRole.ENGINEER,
    ],
  });
}

export function useCanGenerateAutoIdCards(): boolean {
  return usePermission({
    scopes: [AuthRole.ENGINEER, AuthRole.ACCOUNT_MANAGER, AuthRole.CSA],
  });
}

export function useShouldAssignForceId(accountLike: { programType: ProgramType }): boolean {
  const isEb = accountLike.programType === ProgramType.EMPLOYEE_BENEFITS;
  const hasRole = usePermission({
    scopes: [AuthRole.BUSINESS_OPERATIONS, AuthRole.SERVICE_OPS, AuthRole.ENGINEER],
  });
  return isEb && hasRole;
}

export function useCanAddServicingTeamMember(): boolean {
  return usePermission({
    scopes: [
      AuthRole.SERVICE_OPS,
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.CLIENT_SERVICE_TEAM_ADMIN,
      AuthRole.CSA,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.SALES_MANAGER,
      AuthRole.CONVERSION,
      AuthRole.ENGINEER,
    ],
  });
}

export function useCanAddBORPolicy(account: WithEmployeeRoleMappings): boolean {
  const hasAdminAccess = usePermission({ scopes: [AuthRole.ENGINEER] });

  const hasOwnershipAccess = useHasOwnershipAccess(account, [
    AuthRole.ACCOUNT_MANAGER,
    AuthRole.CSA,
    AuthRole.ENGINEER,
    AuthRole.OPERATIONS,
  ]);

  return hasAdminAccess || hasOwnershipAccess;
}

export function useCanDeleteInsurableItem(): boolean {
  return usePermission({ scopes: [AuthRole.ENGINEER, AuthRole.SERVICE_OPS] });
}

export function useCanSeeContractReview(account: DetailedAccount): boolean {
  const userHasContractReviewAccess = usePermission({
    scopes: [
      ...AM_ROLES,
      ...PRODUCER_ROLES,
      AuthRole.ENGINEER,
      AuthRole.EXECUTIVE,
      AuthRole.AM_MANAGER,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.INSURANCE_OPS,
    ],
  });

  const contractAssessmentFlag = useFeatureFlag('contract-assessment-dev-team', false);
  const isAccountEnabledForContractAssessment = useFeatureFlag('contract-assessment-accounts', false, account.uuid);
  const isContractReviewEnabled = contractAssessmentFlag || isAccountEnabledForContractAssessment;

  const isPnC = account.programType === ProgramType.PROPERTY_AND_CASUALTY;

  return isContractReviewEnabled && isPnC && userHasContractReviewAccess;
}

export function useIsServicingTeamMember(): boolean {
  return usePermission({ scopes: [AuthRole.ACCOUNT_MANAGER, AuthRole.CSA, AuthRole.PRODUCER, AuthRole.ENGINEER] });
}
